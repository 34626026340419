import React from "react";
import Layout from "../components/layout";
import Back from "../components/backbutton";

function HotelPage() {
  return (
    <Layout>
     
     <Back></Back>
   
  
      <section className="flex grid p-3 mb-4 text-2xl font-bold "> 
      
       <h1>
        NEWS
  </h1>  
<div>
<h2>Corona Informationen</h2>
<p></p>
</div>
     
   
      </section>
        
     
    </Layout>
  );
}

export default HotelPage;
