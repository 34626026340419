import React from "react";
import backbutton from "../images/backbutton.svg";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import hawlogo from "../images/logo.svg";
function BackButton() {

    return(
        <div className="flex items-center p-2 m-2 justify-between mx-auto">
        <AniLink to="/">
       <p className="inline-block">
       <img className="" src={backbutton}></img>zurück</p>         
       </AniLink>  
       <img className="h-24" src={hawlogo}></img>      
        </div>

    )
}
export default BackButton;